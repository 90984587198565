<template>
  <div>
    <Header />
    <div class="md:h-480 h-96 w-full bg-black bg-opacity-30 relative">
      <img
        src="../assets/hero-about.png"
        alt="Hero About"
        class="h-full w-full absolute object-cover mix-blend-overlay -z-10"
      />
      <div
        class="h-full w-full flex justify-center items-center text-center text-white font-bold"
      >
        <h1 class="md:text-7xl text-4xl">Tentang</h1>
      </div>
    </div>
    <div class="pt-14 md:mx-32 mx-6">
      <div class="md:flex flex-row gap-10">
        <img
          src="../assets/logo-tripwe-app.png"
          alt="Logo Tripwe App"
          class="mx-auto md:mx-0"
        />
        <div class="flex flex-col justify-start md:gap-5 gap-2 mt-8 md:mt-0">
          <h1 class="sm:text-5xl text-2xl font-bold text-black">
            We’re the 1st Adventure Tourism Marketplace in Asia
          </h1>
          <p class="font-semibold text-gray-500 md:text-lg text-base">
            PT Tripedia Global Adventura
          </p>
          <p class="text-grey-2 md:text-lg text-base">
            Tripwe menyediakan layanan digital untuk adventure marketplace. Kami
            memberikan user pilihan berpetualang di laut, sungai, danau dengan
            menggunakan personal watercraft (PWC), Boat, Yacht serta di daratan
            seperti hutan, lembah, dan gunung melalui offroad experience dengan
            kendaraan ATV, UTV, dan SSV dengan kemudahan akses dan pemesanan.
            Tripwe memulai layanan digital di Indonesia.
          </p>
        </div>
      </div>
      <div class="flex flex-col md:gap-10 gap-6 text-center">
        <img
          src="../assets/guide-tripwe-app.jpg"
          alt="Guide Tripwe App"
          class="mt-8"
        />
        <div class="flex flex-col md:gap-5 gap-2">
          <h3 class="font-bold md:text-xl text-lg text-black">
            First Adventure Marketplace in Indonesia
          </h3>
          <p class="md:text-lg text-base text-gray-500">
            Di saat semua orang mencari opsi hiburan yang dapat diakses sambil
            menghormati social distancing, Tripwe adalah solusi yang tepat.
            Tripwe memberi para pencari petualangan jalan keluar untuk
            menjelajahi yang belum dijelajahi dengan cara yang benar-benar unik,
            dan membuat apa yang dulunya hanya dapat diakses oleh beberapa
            orang, dapat diakses oleh siapa saja.
          </p>
        </div>
      </div>
      <div class="md:flex flex-row gap-8 my-8">
        <div class="md:w-1/2">
          <div class="flex flex-col gap-6">
            <h3 class="font-semibold text-2xl">Fitur Layanan Tripwe</h3>
            <p class="text-lg text-green-1">
              Dapat diakses melalui platfom
              <span class="font-bold">Android</span>,
              <span class="font-bold">iOS</span>, dan
              <span class="font-bold">Web</span>
            </p>
            <div class="flex flex-col gap-4">
              <div class="flex flex-col justify-end">
                <div class="flex flex-row gap-4 items-center">
                  <font-awesome-icon
                    icon="fa-regular fa-circle-dot"
                    class="text-green-1"
                  />
                  <p class="font-medium text-gray-500 md:text-xl text-lg">
                    Water Adventure Marketplace
                  </p>
                </div>
                <p class="ml-8 text-sm text-grey-2 md:text-base">
                  Berbagai aktivitas petualangan di air
                </p>
              </div>
              <div class="flex flex-col justify-end">
                <div class="flex flex-row gap-4 items-center">
                  <font-awesome-icon
                    icon="fa-regular fa-circle-dot"
                    class="text-green-1"
                  />
                  <p class="font-medium text-gray-500 md:text-xl text-lg">
                    Off-Road Adventure Marketplace
                  </p>
                </div>
                <p class="ml-8 text-sm text-grey-2 md:text-base">
                  Petualangan menyusuri medan alam
                </p>
              </div>
              <div class="flex flex-col justify-end">
                <div class="flex flex-row gap-4 items-center">
                  <font-awesome-icon
                    icon="fa-regular fa-circle-dot"
                    class="text-green-1"
                  />
                  <p class="font-medium text-gray-500 md:text-xl text-lg">
                    On-Road Adventure Marketplace
                  </p>
                </div>
                <p class="ml-8 text-sm text-grey-2 md:text-base">
                  Petualangan menyusuri jalan aspal
                </p>
              </div>
              <div class="flex flex-col justify-end">
                <div class="flex flex-row gap-4 items-center">
                  <font-awesome-icon
                    icon="fa-regular fa-circle-dot"
                    class="text-green-1"
                  />
                  <p class="font-medium text-gray-500 md:text-xl text-lg">
                    Bursa
                  </p>
                </div>
                <p class="ml-8 text-sm text-grey-2 md:text-base">
                  Lapak penjualan personal watercraft (PWC) dan kendaraan
                  offroad bekas
                </p>
              </div>
              <div class="flex flex-col justify-end">
                <div class="flex flex-row gap-4 items-center">
                  <font-awesome-icon
                    icon="fa-regular fa-circle-dot"
                    class="text-green-1"
                  />
                  <p class="font-medium text-gray-500 md:text-xl text-lg">
                    Tripwe Shop
                  </p>
                </div>
                <p class="ml-8 text-sm text-grey-2 md:text-base">
                  Toko aksesoris dan perlengkapan berpetualang
                </p>
              </div>
              <div class="flex flex-col justify-end">
                <div class="flex flex-row gap-4 items-center">
                  <font-awesome-icon
                    icon="fa-regular fa-circle-dot"
                    class="text-green-1"
                  />
                  <p class="font-medium text-gray-500 md:text-xl text-lg">
                    Tripwe Pay
                  </p>
                </div>
                <p class="ml-8 text-sm text-grey-2 md:text-base">
                  Kemudahan untuk bertransaksi tanpa batas, dengan mudah
                </p>
              </div>
            </div>
            <p class="md:text-2xl text-lg">
              Menikmati sensasi alam bebas tanpa harus memiliki kendaraan
              sendiri.
            </p>
          </div>
        </div>
        <div class="md:w-1/2 bg-grey-1 md:p-12 p-8 mt-8 md:mt-0">
          <div class="flex flex-col gap-8">
            <img src="../assets/quote-about.jpeg" alt="" />
            <div class="flex flex-row gap-5 items-start">
              <h1 class="font-italic font-bold text-6xl">"</h1>
              <div class="flex flex-col gap-5">
                <p class="font-italic">
                  Hadirnya inovasi dalam aplikasi tripwe sekaligus membantu
                  program pemerintah untuk memperkenalkan sport tourism yang
                  akan menjadi backbound pariwisata Indonesia di era new normal.
                </p>
                <p>
                  <span class="font-bold">- Dr. Sofian Lusa</span> / CEO & Co
                  Founder Tripwe
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";

export default {
  components: { Header, Footer },
  data: () => ({
    heroStyle: {
      backgroundImage: "url('./src/assets/hero-about.png')",
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
    },
  }),
};
</script>
